<template>
    <auth-layout pattern="red">
        <h1 class="fw-bold mb-3">
            {{ $t('Zabudnuté heslo') }}
        </h1>
        <form @submit.prevent="submitForm">
            <div class="form-floating mb-3">
                <input id="email" v-model="input.email.value" :class="'form-control ' + isValidInput(input.email)" placeholder="" type="email">
                <label for="email">{{ $t('E-mail') }}</label>
            </div>
            <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                <submit-button :disabled="! canSubmit || fetching" :fetching="fetching" :title="submitButtonTitle"></submit-button>
                <router-link :to="{name: 'login'}" class="fw-bold btn btn-primary mt-2 mt-lg-0 px-3">
                    {{ $t('Prihlásiť sa') }}
                </router-link>
            </div>
        </form>
    </auth-layout>
</template>

<script>
import v8n from 'v8n'
import SubmitButton from '../component/SubmitButton'
import AuthLayout from '../layout/AuthLayout'
import { canSubmitForm } from '@/util/canSubmitForm'
import { prepareData } from '@/util/prepareData'
import { formInput } from '@/util/formInput'
import { validInput } from '@/util/validInput'
import { EMAIL_REGEX } from '@/util/regex'
import { forEachObjIndexed } from 'ramda'

export default {
    components: { AuthLayout, SubmitButton },
    computed: {
        canSubmit () {
            return canSubmitForm(this.input)
        },
        fetching () {
            return this.$store.getters['auth/isFetching']
        },
        submitButtonTitle () {
            return this.$t('Obnoviť heslo')
        },
    },
    created () {
        const self = this
        
        forEachObjIndexed(function (inputObj, key) {
            self.$watch(
                function () {
                    return inputObj?.value
                },
                function (value) {
                    const input = self.input[key]
                    
                    if (! input) return
                    
                    input.dirty = true
                    input.valid = input.validation(value)
                },
            )
        }, this.input)
    },
    data () {
        return {
            input: {
                email: formInput({
                    validation: value => v8n()
                        .pattern(EMAIL_REGEX)
                        .test(value),
                }),
            },
        }
    },
    methods: {
        isValidInput (input) {
            return validInput(input)
        },
        submitForm () {
            if (! this.canSubmit) return
            
            this.$store.dispatch('auth/forgotPassword', prepareData(this.input))
        },
    },
    name: 'ForgotPassword',
}
</script>
